/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2024-09-02 11:17:13
 */ 

// api列表
import {autoLoadModule} from "@/utils/autoLoadModule.js";
// element
import Vue from "vue";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '../src/utils/table-sticky'

import App from "./App.vue";
Vue.use(ElementUI);

// 收缩
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)


// // app.js
import './assets/css/app.css'
import './assets/scss/common.scss'
import './assets/icon/iconfont.css'
import './assets/scss/app.scss'

import 'bootstrap/dist/css/bootstrap.min.css'

import '@/views/certificate/form/components/DynamicForm/styles/index.styl'
import '@/views/certificate/form/components/DynamicForm/icons'

import FormControls from '@/views/certificate/form/components/FormControls/index.js'
Vue.use( FormControls )

//moment.js
import moment from 'moment';
Vue.prototype.$moment = moment

//滑动验证码
import slideVerify from 'vue-monoplasty-slide-verify';
Vue.use(slideVerify);

// 公共组件-导航栏
import navbar from '@/components/navbar.vue'   //导入组件
Vue.component('navbar', navbar)  //组册公用组件
// 公共组件-菜单栏
import auxAside from '@/components/auxAside.vue'   //导入组件
Vue.component('auxAside', auxAside)  //组册公用组件

// 公共组件-导底部
import foot from '@/components/footer.vue'   //导入组件
Vue.component('foot', foot)  //组册公用组件

// 公共组件-图片上传
import imgUploader from '@/components/imgUploader.vue'   //导入组件
Vue.component('imgUploader', imgUploader)  //组册公用组件
Vue.config.productionTip = false;

// 公共组件-图片上传
import uploader from 'vue-simple-uploader'
Vue.use(uploader)  //组册公用组件

// 公共组件-表格

// 公共组件-导底部

import tables from '@/components/tables.vue'
Vue.component('tables', tables)  //组册公用组件

// 公共组件-表格
import dialogPopUp from '@/components/dialogPopUpV2.vue'
Vue.component('dialogPopUp', dialogPopUp)  //组册公用组件

// 按需引入dataV
import dataVComponents from "./components/dataV";
Vue.use(dataVComponents);

// // 按需引入elementUi
// import elementComponents from "./components/elementUi";
// Vue.use(elementComponents);

//引入右键菜单
import VueContextMenu from 'vue-contextmenu'
Vue.use(VueContextMenu)


// 多语言
import i18n from './lang' 

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

// Vue.use( elementComponents,{
//   i18n: (key, value) => i18n.t(key, value)
// })

import { getMenu, getModuleAside } from "./views/platform/api/menu"
Vue.prototype.getMenu = getMenu;
Vue.prototype.getModuleAside = getModuleAside;

Vue.prototype.loading = function() {
  const loading = this.$loading({           // 声明一个loading对象
    lock: true,                             // 是否锁屏
    text: i18n.t('global.loading'),                     // 加载动画的文字
    spinner: 'el-icon-loading',             // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.3)',       // 背景颜色
    target: '.sub-main',                    // 需要遮罩的区域
    body: true,                              
    customClass: 'mask'                     // 遮罩层新增类名
  })
  return loading;
}

/**
 * @Descripttion: 获取导航栏数据
 * @name: getMenuList
 * @Author: Terry
 * @Date: 2021-04-11 11:44:24
 * @param {*} api api地址
 * @param {*} key 存放缓存的key
 */
Vue.prototype.getMenuList = async function(func,params,key) {
  await Vue.prototype[func](params).then(res=>{
      sessionStorage.setItem(key+'Aside', JSON.stringify(res.data.aside));
      sessionStorage.setItem(key+'ButtonType', JSON.stringify(res.data.button));
  })
}

autoLoadModule().then(res=>{
  var router = res.router;
//  添加路由导航守卫
  router.beforeEach(async (to, from, next) => {
    var white = ['/auth/register','/auth/forget'];
    // 获取token
    var token = sessionStorage.getItem('auxfaceToken')
    if(white.indexOf(to.path) > -1) next();
    // 获取项目uuid 和公司uuid
    else if (token==undefined || token=='undefined' ) {
      // 没有登陆的情况下，非登陆页面均跳转登陆页面
      if(to.path != '/auth/login') next({ name: 'login' }); 
      else next();
    }else {
      /*登录成功下，访问登陆页面*/
      if(to.path == '/auth/login' || to.path == '/') next({name:process.env.VUE_APP_HOME});
      else {
        var path = to.path.split('/'),
        menuKey = path[1];
          //模块内部页面
        if(path[1]=='modules'){
          if(!sessionStorage.getItem('puuid'))  next({ name: 'projectList' });
          if(path[2]!=""&&path[2] != undefined){
            //获取左侧导航栏数据
            var moduleName = path[2], 
            method  = 'getModuleAside';
            //切换模块的时候 返回前进需要清楚左侧栏重新获取
            let oldPath = from.path.split('/');
            from.fullPath == '/'  && sessionStorage.getItem(menuKey+'Aside')&&sessionStorage.removeItem(menuKey+'Aside')
            //判断返回的时候模块是否有变化 
            if(oldPath.length>2 && oldPath[2] != path[2] && moduleName != sessionStorage.getItem('module')) {
                sessionStorage.removeItem(menuKey+'Aside');
            }
          }else{
            next();
          }
        }else{
          // 非项目版本(获取账号本身的企业状态)
          let corpCode = sessionStorage.getItem("myCorpCode");
          sessionStorage.setItem("corpCode", corpCode);
          // 移除项目版本的东西
          sessionStorage.removeItem('puuid');
          sessionStorage.removeItem('projectName');
          sessionStorage.removeItem('module');
          
          var moduleName = path[1],
          method  = 'getMenu';
        }
        // if(!sessionStorage.getItem(menuKey+'Aside')){
          await Vue.prototype.getMenuList(method,{'module':moduleName},menuKey);
        // }
          next();
      } 
    }
    //判断meta设置网页标签
    if(to.matched.length>0){
      let title = i18n.t('global.title');
      let metaTitle = i18n.t((to.matched[0].meta.title));
      if(metaTitle  != '' && metaTitle  != undefined){
        let prjName = sessionStorage.getItem('projectName');
        if(prjName  != undefined) title = metaTitle + '-' + prjName;
        else  title  = metaTitle;
      }
        document.title = title;
    }
  })

  
  /** 权限指令,对按钮权限的控制 **/
  Vue.directive('has', {
    update: function (el, binding) {
      if (Vue.prototype.checkBtn(binding.value)) {
        el.style.display="block";
      }else{
        el.style.display="none";
      }
    },
    bind: function(el, binding) {
        if (!Vue.prototype.checkBtn(binding.value)) {
            el.style.display="none";
        }
    },
    
  })

  // 权限检查方法（且把该方法添加到vue原型中）
  Vue.prototype.checkBtn = function(value) {

    var path = router.history.current.fullPath.split('/'),
    menuKey = path[1]+'ButtonType',
    isExist = false,
    buttonpermsStr = sessionStorage.getItem(menuKey);
    if (buttonpermsStr === undefined || buttonpermsStr == null) {
        return false
    }
    var btnCheck = value.split(',');
  
    btnCheck.forEach(value => {
      if (buttonpermsStr.indexOf(value) > -1) {
          // 若在按钮中定义的权限字段能在后端返回的权限数组中能找到，则该按钮可显示
          isExist = true;
      }
    });
    return isExist
  }

    new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
      beforeCreate(){                 //在初始化阶段前
        Vue.prototype.$bus = this   //配置全局总线，bus有总线的意思
      }
    }).$mount("#app");
}).catch(res=>{
})



