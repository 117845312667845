/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2021-09-27 17:11:20
 */ 

const video = {
  'video':{
    'video':{
      'getList':'video/admin/v1/video/getList',
      'getChannelData':'video/admin/v1/camera/getChannelData',
      'update':'video/admin/v1/video/update',
    },
    'camera':{
      'getSetting':'video/admin/v1/camera/getSetting',
      'updateSetting':'video/admin/v1/camera/updateSetting',
      'getList':'video/admin/v1/camera/getList',
      'getChannelData':'video/admin/v1/camera/getChannelData',
      'update':'video/admin/v1/camera/update',
      'create':'video/admin/v1/camera/create',
      'destroy':'video/admin/v1/camera/destroy',
      'getDetail':'video/admin/v1/camera/getCameraDetail',
      'getDic':'video/admin/v1/camera/getDic',
      'changeStatus':'video/admin/v1/camera/changeStatus',
      'getNvrChannel':'video/admin/v1/camera/getNvrChannel'
    },
    'config':{
      'getList':'video/admin/v1/config/getList',
      'create':'video/admin/v1/config/create',
      'update':'video/admin/v1/config/update',
      'destroy':'video/admin/v1/config/destroy',
    },
  }
}
module.exports =  video;



