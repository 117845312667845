/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2022-09-22 17:34:04
 */ 

const projectInfo = {
  'projectInfo':{
    'rule':{
      'getDetail':'projectInfo/admin/v1/rule/getDetail',
      'getRuleGroup':'projectInfo/admin/v1/rule/getRuleGroup',
      'update':'projectInfo/admin/v1/rule/update',
      'create':'projectInfo/admin/v1/rule/create',
      'del':'projectInfo/admin/v1/rule/destroy',
      // 'getUser':'projectInfo/admin/v1/rule/getUser',
      // 'userManage':"projectInfo/admin/v1/rule/userManage",
      'getRules':"projectInfo/admin/v1/rule/getRules",
    },
    'permission':{
      'getAccount':'projectInfo/admin/v1/permission/getAccount',
      'create':'projectInfo/admin/v1/permission/create',
      'destroy':'projectInfo/admin/v1/permission/destroy',
      'getList':'projectInfo/admin/v1/permission/getList',
    },
    'project':{
      "getData":'projectInfo/admin/v1/project/getData',
      "update":'projectInfo/admin/v1/project/update',
      "getDic":'projectInfo/admin/v1/project/getDic',
      "getAddDic":'projectInfo/admin/v1/project/getAddDic',
      'destroy':"projectInfo/admin/v1/project/destroy",
    },
    "builderLicense":{
      "getList":'projectInfo/admin/v1/builderLicense/getList',
      "update":'projectInfo/admin/v1/builderLicense/update',
      "create":'projectInfo/admin/v1/builderLicense/create',
      "destroy":'projectInfo/admin/v1/builderLicense/destroy',
    },
    'projectCorp':{
      "getList":'projectInfo/admin/v1/projectCorp/getList',
      "getDic":'projectInfo/admin/v1/projectCorp/getDic',
      "update":'projectInfo/admin/v1/projectCorp/update',
      "create":'projectInfo/admin/v1/projectCorp/create',
      'entry':'projectInfo/admin/v1/projectCorp/entry',
      'exit':'projectInfo/admin/v1/projectCorp/exit',
      'destroy':'projectInfo/admin/v1/projectCorp/destroy',
      'getRoleList':'projectInfo/admin/v1/projectCorp/getRoleList',
      'updatePower':'projectInfo/admin/v1/projectCorp/addRole',
      'getRandom':'projectInfo/admin/v1/projectCorp/getRandom',
      'updateRandom':'projectInfo/admin/v1/projectCorp/createRandom'
    },
    'integrator':{
      'getIntegratorMachineType':'projectInfo/admin/v1/integrator/getIntegratorMachineTypeDic',
      'getList':'projectInfo/admin/v1/integrator/getList',
      'applyList':'projectInfo/admin/v1/integrator/applyList',
      'audit':'projectInfo/admin/v1/integrator/audit',
    },
    'setting':{
      'getSetting':'projectInfo/admin/v1/setting/getSetting',
      'updateSetting':'projectInfo/admin/v1/setting/updateSetting',
    },
    'governmentConfig':{
      'getList':'projectInfo/admin/v1/governmentConfig/getList',
      'getIntegrator':'projectInfo/admin/v1/governmentConfig/getIntegrator',
      'create':'projectInfo/admin/v1/governmentConfig/create',
      'update':'projectInfo/admin/v1/governmentConfig/update',
      'destroy':'projectInfo/admin/v1/governmentConfig/destroy'
    }
  }
}
module.exports =  projectInfo;



