/*
 * @Descripttion: 
 * @version: v1
 * @Author: Terry
 * @Date: 2021-11-20 09:50:59
 * @LastEditors: Terry
 * @LastEditTime: 2021-12-25 18:31:23
 */
const greenSite={
    'greenSite':{
        'dust':{
            'getList':'greenSite/admin/v1/dust/getList',
            'create':'greenSite/admin/v1/dust/create',
            'thresholdData':'greenSite/admin/v1/dust/thresholdData',
            'update':'greenSite/admin/v1/dust/update',
            'detail':'greenSite/admin/v1/dust/detail',
            'destroy':'greenSite/admin/v1/dust/destroy'
        },
        'electric':{
            'getList':'greenSite/admin/v1/electric/getList',
            'create':'greenSite/admin/v1/electric/create',
            'update':'greenSite/admin/v1/electric/update',
            'destroy':'greenSite/admin/v1/electric/destroy',
            'detail':'greenSite/admin/v1/electric/detail',
            'thresholdData':'greenSite/admin/v1/electric/thresholdData'
        },
        'water':{
            'getList':'greenSite/admin/v1/water/getList',
            'create':'greenSite/admin/v1/water/create',
            'update':'greenSite/admin/v1/water/update',
            'destroy':'greenSite/admin/v1/water/destroy',
            'detail':'greenSite/admin/v1/water/detail',
            'thresholdData':'greenSite/admin/v1/water/thresholdData'
        }
    }
}
module.exports =  greenSite;