/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2021-12-28 16:20:42
 */ 

const warning = {
  'warning':{
    'common':{
        'getWarningType':'warning/admin/v1/common/getWarningType',
    },
    'dust':{
        'getList':'warning/admin/v1/dust/getList',
        'getSetting':'warning/admin/v1/dust/getSetting',
        'updateSetting':'warning/admin/v1/dust/updateSetting',
        'getDetail':'warning/admin/v1/dust/getDetail',
    },
    'worker':{
        'getList':'warning/admin/v1/worker/getList',
        'getSetting':'warning/admin/v1/worker/getSetting',
        'updateSetting':'warning/admin/v1/worker/updateSetting',
    },
    'electric':{
        'getList':'warning/admin/v1/electric/getList',
        'getSetting':'warning/admin/v1/electric/getSetting',
        'updateSetting':'warning/admin/v1/electric/updateSetting',
        'getDetail':'warning/admin/v1/electric/getDetail',
    },
    'water':{
        'getList':'warning/admin/v1/water/getList',
        'getSetting':'warning/admin/v1/water/getSetting',
        'updateSetting':'warning/admin/v1/water/updateSetting',
        'getDetail':'warning/admin/v1/water/getDetail',
    },
  }
}
module.exports =  warning;