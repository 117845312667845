/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2021-09-27 17:09:09
 */ 

const mobileAttendance = {
  'mobileAttendance':{
        'region':{
          "getList":'mobileAttendance/admin/v1/region/getList',
          "getMachineteamName":'mobileAttendance/admin/v1/region/getMachineteamName',
          "create":'mobileAttendance/admin/v1/region/create',
          "update":'mobileAttendance/admin/v1/region/update',
          "destroy":'mobileAttendance/admin/v1/region/destroy',
        },
        'attendance':{
          "getList":'mobileAttendance/admin/v1/attendance/getList',
          "details":'mobileAttendance/admin/v1/attendance/details',
          "update":'mobileAttendance/admin/v1/attendance/update',
          // "getWorker":'mobileAttendance/admin/v1/attendance/getWorker',
        },
        // 人脸考勤
        'face':{
          "getList":'mobileAttendance/admin/v1/face/getList'
        },
        // 可随机人员选择
        'select':{
          "details":'mobileAttendance/admin/v1/random/details',
          "update":'mobileAttendance/admin/v1/random/update'
        }
  }
}

module.exports =  mobileAttendance;



