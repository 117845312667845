const corp ={
    'corp':{
        //分类管理
        'classcenter':{
            'getList':"safetytrain/admin/v1/classcenter/getList",
            'getInfoDic':"safetytrain/admin/v1/classcenter/getInfoDic",
            'create':"safetytrain/admin/v1/classcenter/create",
            'update':"safetytrain/admin/v1/classcenter/update",
            'destroy':"safetytrain/admin/v1/classcenter/destroy",
        },
        //课程中心
        'center':{
            'getList':'safetytrain/admin/v1/center/getList',
            'getClassType':'safetytrain/admin/v1/center/getClassType',
            'getInfoDic':'safetytrain/admin/v1/center/getInfoDic',
            'create':'safetytrain/admin/v1/center/create',
            'update':"safetytrain/admin/v1/center/update",
            'destroy':"safetytrain/admin/v1/center/destroy",
        },
        //详细数据
        'centerDetails':{
            'getList':"safetytrain/admin/v1/centerDetails/getList",
            'getDic':"safetytrain/admin/v1/centerDetails/getDic",
            'getClassType':"safetytrain/admin/v1/centerDetails/getClassType",
            'create':"safetytrain/admin/v1/centerDetails/create",
            'update':"safetytrain/admin/v1/centerDetails/update",
            'destroy':"safetytrain/admin/v1/centerDetails/destroy",
            'getExamList':"safetytrain/admin/v1/centerDetails/getExamList",
        },
        //题库管理
        'questionBank':{
            'getList':'safetytrain/admin/v1/questionbank/getList',
            'getClassType':'safetytrain/admin/v1/questionbank/getClassType',
            'create':'safetytrain/admin/v1/questionbank/create',
            'destroy':'safetytrain/admin/v1/questionbank/destroy',
            'update':'safetytrain/admin/v1/questionbank/update',
            'getOption':'safetytrain/admin/v1/questionbank/getOption'
        },
        //试卷管理
        'Paper':{
            'getList':'safetytrain/admin/v1/paper/getList',
            'getClassType':'safetytrain/admin/v1/paper/getClassType',
            'create':'safetytrain/admin/v1/paper/create',
            'update':'safetytrain/admin/v1/paper/update',
            'getBank':'safetytrain/admin/v1/paper/getBank',
            'destroy':'safetytrain/admin/v1/paper/destroy',
            'getOptions':'safetytrain/admin/v1/paper/getOptions',
            'getDetailList':'safetytrain/admin/v1/paperQuestion/getList',
            'createDetail':'safetytrain/admin/v1/paperQuestion/create',
            'sync':'safetytrain/admin/v1/paperQuestion/sync',
            'detailDestroy':'safetytrain/admin/v1/paperQuestion/destroy',
            'revies':'safetytrain/admin/v1/paperQuestion/revies'
        },
        //公共接口
        'common':{
            'getClassType':'safetytrain/admin/v1/common/getClassType'
        }
    }
}
module.exports =  corp;