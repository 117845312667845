/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2021-12-25 18:31:03
 */ 

const integrator = {
  'integrator':{
      'testProject':{
        'getList':'integrator/admin/v1/testProject/getList',
      },
      'staff':{
        'getList':'integrator/admin/v1/staff/getList',
        'create':'integrator/admin/v1/staff/create',
        'update':'integrator/admin/v1/staff/update',
        'entryExit':'integrator/admin/v1/staff/entryExit',
      },
      'faceMachine':{
        'getList':'integrator/admin/v1/faceMachine/getList',
        'create':'integrator/admin/v1/faceMachine/create',
        'update':'integrator/admin/v1/faceMachine/update',
        'destroy':'integrator/admin/v1/faceMachine/destroy',
        'checkStatus':'integrator/admin/v1/faceMachine/checkStatus',
      },
      'attendance':{
        'getList':'integrator/admin/v1/attendance/getList',
      },
      'project':{
        //项目管理
        'getList':'integrator/admin/v1/project/getList',
        'create':'integrator/admin/v1/project/create',
        'update':'integrator/admin/v1/project/update',
        'machineType':'integrator/admin/v1/project/machineType',
        //待审核项目
        'applyList':'integrator/admin/v1/project/applyList',
        'applyUpdate':'integrator/admin/v1/project/applyUpdate',
      },
      //扬尘管理
      'dust':{
        'getList':'integrator/admin/v1/dust/getList',
        'create':'integrator/admin/v1/dust/create',
        'threshold':'greenSite/admin/v1/dust/thresholdData',
        'update':'integrator/admin/v1/dust/update',
        'detail':'integrator/admin/v1/dust/detail',
        'destroy':'integrator/admin/v1/dust/destroy',
        'checkStatus':'integrator/admin/v1/dust/checkStatus'
      },
      //水表管理
     'water':{
      'getList':'integrator/admin/v1/water/getList',
      'create':'integrator/admin/v1/water/create',
      'threshold':'greenSite/admin/v1/water/thresholdData',
      'update':'integrator/admin/v1/water/update',
      'detail':'integrator/admin/v1/water/detail',
      'destroy':'integrator/admin/v1/water/destroy',
      'checkStatus':'integrator/admin/v1/water/checkStatus'
      },
      //电表管理
      'electric':{
        'getList':'integrator/admin/v1/electric/getList',
        'create':'integrator/admin/v1/electric/create',
        'threshold':'greenSite/admin/v1/electric/thresholdData',
        'update':'integrator/admin/v1/electric/update',
        'detail':'integrator/admin/v1/electric/detail',
        'destroy':'integrator/admin/v1/electric/destroy',
        'checkStatus':'integrator/admin/v1/electric/checkStatus'
    },
    //塔吊管理
      'towerCrane':{
        'getList':'integrator/admin/v1/towerCrane/getList',
        'create':'integrator/admin/v1/towerCrane/create',
        'update':'integrator/admin/v1/towerCrane/update',
        'detail':'integrator/admin/v1/towerCrane/detail',
        'destroy':'integrator/admin/v1/towerCrane/destroy',
        'checkStatus':'integrator/admin/v1/towerCrane/checkStatus'
    },
    //升降机管理
    'elevator':{
      'getList':'integrator/admin/v1/elevator/getList',
      'create':'integrator/admin/v1/elevator/create',
      'update':'integrator/admin/v1/elevator/update',
      'detail':'integrator/admin/v1/elevator/detail',
      'destroy':'integrator/admin/v1/elevator/destroy',
      'checkStatus':'integrator/admin/v1/elevator/checkStatus'
  },
      'common':{
        'getList':'common/admin/v1/integrator/getIntegrator'
    }
  },
}

module.exports =  integrator;

