const equipment={
    'equipment':{
        'elevator':{
            'getList':'equipment/admin/v1/elevator/getList',
            'create':'equipment/admin/v1/elevator/create',
            'update':'equipment/admin/v1/elevator/update',
            'detail':'equipment/admin/v1/elevator/detail',
            'destroy':'equipment/admin/v1/elevator/destroy'
        },
        'common':{
            'getIntegrator':'common/admin/v1/integrator/getIntegrator',
            'getCameraList':'common/admin/v1/camera/cameraList'

        },
        'towerCrane':{
            'getList':'equipment/admin/v1/towerCrane/getList',
            'create':'equipment/admin/v1/towerCrane/create',
            'update':'equipment/admin/v1/towerCrane/update',
            'detail':'equipment/admin/v1/towerCrane/detail',
            'destroy':'equipment/admin/v1/towerCrane/destroy'
        }
    }
}
module.exports =  equipment;