const safetytrain={
    'safetytrain':{
        //考试数据
        'overview':{
            'getdata':'safetytrain/admin/v1/overview/getdata',
        },
        // //分类管理
        // 'classcenter':{
        //     'getList':"safetytrain/admin/v1/classcenter/getList",
        //     'getInfoDic':"safetytrain/admin/v1/classcenter/getInfoDic",
        //     'create':"safetytrain/admin/v1/classcenter/create",
        //     'update':"safetytrain/admin/v1/classcenter/update",
        //     'destroy':"safetytrain/admin/v1/classcenter/destroy",
        // },
        // //课程中心
        'center':{
            'getList':'safetytrain/admin/v1/center/getList',
            'getClassType':'safetytrain/admin/v1/center/getClassType',
            'getInfoDic':'safetytrain/admin/v1/center/getInfoDic',
            'create':'safetytrain/admin/v1/center/create',
            'update':"safetytrain/admin/v1/center/update",
            'destroy':"safetytrain/admin/v1/center/destroy",
        },
        //详细数据
        // 'centerDetails':{
        //     'getList':"safetytrain/admin/v1/centerDetails/getList",
        //     'getDic':"safetytrain/admin/v1/centerDetails/getDic",
        //     'getClassType':"safetytrain/admin/v1/centerDetails/getClassType",
        //     'create':"safetytrain/admin/v1/centerDetails/create",
        //     'update':"safetytrain/admin/v1/centerDetails/update",
        //     'destroy':"safetytrain/admin/v1/centerDetails/destroy",
        //     'getExamList':"safetytrain/admin/v1/centerDetails/getExamList",
        // },
        //用户课程管理
        'usercenter':{
            'getList':"safetytrain/admin/v1/usercenter/getList",
            'getCenterUser':"safetytrain/admin/v1/usercenter/getCenterUser",
            'getWorkerList':"safetytrain/admin/v1/usercenter/getWorkerList",
            'update':"safetytrain/admin/v1/usercenter/update",
            'destroy':"safetytrain/admin/v1/usercenter/destroy",
        },
        //考试管理
        'examination':{
            'getList':'safetytrain/admin/v1/exam/getList',
            // 'update':'safetytrain/admin/v1/examination/update',
            // 'destroy':'safetytrain/admin/v1/examination/destroy',
            'getInfoDic':"safetytrain/admin/v1/exam/getClassType",
            'getTeamName':"safetytrain/admin/v1/exam/getTeamName",
        },
        // //题库管理
        // 'questionBank':{
        //     'getList':'safetytrain/admin/v1/questionbank/getList',
        //     'getClassType':'safetytrain/admin/v1/questionbank/getClassType',
        //     'create':'safetytrain/admin/v1/questionbank/create',
        //     'destroy':'safetytrain/admin/v1/questionbank/destroy',
        //     'update':'safetytrain/admin/v1/questionbank/update',
        //     'getOption':'safetytrain/admin/v1/questionbank/getOption'
        // },
        // //试卷管理
        // 'Paper':{
        //     'getList':'safetytrain/admin/v1/paper/getList',
        //     'getClassType':'safetytrain/admin/v1/paper/getClassType',
        //     'create':'safetytrain/admin/v1/paper/create',
        //     'update':'safetytrain/admin/v1/paper/update',
        //     'getBank':'safetytrain/admin/v1/paper/getBank',
        //     'destroy':'safetytrain/admin/v1/paper/destroy',
        //     'getOptions':'safetytrain/admin/v1/paper/getOptions',
        // },
        //未考试人员
        'noExamination':{
            'getList':'safetytrain/admin/v1/noExam/getList',
            'getDetail':'safetytrain/admin/v1/noExam/getDetail'
        },
        //轮播图
        'banner':{
            'getList':'safetytrain/admin/v1/banner/getList',
            'create':'safetytrain/admin/v1/banner/create',
            'destroy':'safetytrain/admin/v1/banner/destroy',
            'update':'safetytrain/admin/v1/banner/update'
        },
        //公共接口
        'common':{
            'getClassType':'safetytrain/admin/v1/common/getClassType',
        }
    }
}
module.exports =  safetytrain;