/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2021-12-25 18:20:20
 */ 

const platform = {
  'platform':{
    'menu':{
      'getMenu':'platform/admin/v1//menu/getMenu',
      'getModuleAside':'platform/admin/v1//menu/getModuleAside',
    },
    'module':{
      'getList':'platform/admin/v1/module/getList',
    },
    'auth': {
      'getQrCode':'platform/admin/v1/auth/loginQrCode',
      'checkLogin':'platform/admin/v1/auth/checkLogin',
      'pcLogin':'platform/admin/v1/auth/pcLogin',
      'register':'platform/admin/v1/auth/register',
      'forget':'platform/admin/v1/auth/forget',
    },
    'sendSms':{
      'getRegisterPhoneVerify':'platform/admin/v1/SendSms/getRegisterPhoneVerify',
      'getBindingPhoneVerify':'platform/admin/v1/SendSms/getBindingPhoneVerify',
      'getForgetPhoneVerify':'platform/admin/v1/SendSms/getForgetPhoneVerify',
      'getUnbindingPhoneVerify':'platform/admin/v1/SendSms/getUnbindingPhoneVerify',
    },
    'project':{
      "create":'platform/admin/v1//project/create',
      "getAddDic":'platform/admin/v1//project/getAddDic',
      "getPrjStatusDic":'platform/admin/v1//project/getPrjStatusDic',
      "getCellData":'platform/admin/v1//project/getCellData',
      "getList":'platform/admin/v1//project/getList',
    },
    'outside':{
      'getData':'platform/admin/v1//outside/getData',
      'getApi':'platform/admin/v1//outside/getApi',
      'getList':'platform/admin/v1//outside/getList',
      'add':'platform/admin/v1//outside/add',
      'apiUseStats':'platform/admin/v1//outside/apiUseStats',
      'del':'platform/admin/v1//outside/destroy',
    },  
    'corp':{
      //企业申请
      'create':'platform/admin/v1//corp/create',
      'getAddDic':'platform/admin/v1//corp/getAddDic',
      'getStatus':'platform/admin/v1//corp/getStatus',
      //企业信息
      'getInfoDic':'platform/admin/v1//corp/getInfoDic',
      'getData':'platform/admin/v1//corp/getData',
      'update':'platform/admin/v1//corp/update',
      //企业资质
      'getCertDic':'platform/admin/v1//corp/getCertDic',
      'certData':'platform/admin/v1//corp/certData',
      'certUpload':'platform/admin/v1//corp/certUpload',
      //企业审批
      'getList':'platform/admin/v1//corp/getList',
      'audit':'platform/admin/v1//corp/audit',
    },
    'home':{
      'getData':'platform/admin/v1//home/getData',
    },
    'finance':{
      'getBill':'platform/admin/v1//finance/getBill'
    },
    'government':{
      'getList':'platform/admin/v1//government/getList',
      'create':'platform/admin/v1//government/create',
      'update':'platform/admin/v1//government/update',
      'searchUser':'platform/admin/v1//government/searchUser',
      'destroy':'platform/admin/v1//government/destroy',
      'getDic':'platform/admin/v1//government/getDic',
    },
    'userCenter':{
      'getUserData': 'platform/admin/v1/userCenter/getUserData',
      'changePassword': 'platform/admin/v1/userCenter/changePassword',
      'accountSet': 'platform/admin/v1/userCenter/accountSet',
      'phoneSet': 'platform/admin/v1/userCenter/phoneSet',
      'getBindingqrCode': 'platform/admin/v1/userCenter/getBindingqrCode',
      'checkBinding': 'platform/admin/v1/userCenter/checkBinding',
      'getUnbindingqrCode': 'platform/admin/v1/userCenter/getUnbindingqrCode',
      'checkUnbinding': 'platform/admin/v1/userCenter/checkUnbinding',
      'unbindingPhone': 'platform/admin/v1/userCenter/unbindingPhone',
    },
  }
}

module.exports =  platform;



