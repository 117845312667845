/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2022-09-09 18:50:55
 */ 

const certification = {
  'certification':{
    'staff':{
      // 班组成员
      "getList":'certification/admin/v1/staff/getList',
      "getOptions":'certification/admin/v1/staff/getOptions',
      "getInfoDic":'certification/admin/v1/staff/getInfoDic',
      "getWorkerDic":'certification/admin/v1/staff/getWorkerDic',
      "audit":'certification/admin/v1/staff/audit',
      "del":'certification/admin/v1/staff/destroy',
      'auditList' : 'certification/admin/v1/staff/auditList',
      'setLeader' : 'certification/admin/v1/staff/setLeader',
      'lossLeader' : 'certification/admin/v1/staff/lossLeader',
      "excelExporte":'certification/admin/v1/staff/excelExporte',
    },
    'contract':{
      "getDic":'certification/admin/v1/contract/getDic',
      "info":'certification/admin/v1/contract/info',
      "update":'certification/admin/v1/contract/update',
      "create":'certification/admin/v1/contract/create',
      "file":'certification/admin/v1/contract/file',
      "fileCreate":'certification/admin/v1/contract/fileCreate',
      "fileUpdate":'certification/admin/v1/contract/fileUpdate',
      "fileDestroy":'certification/admin/v1/contract/fileDestroy',
    },
    'workerInfo':{
      "getData":'certification/admin/v1/workerInfo/getData',
      "getProjectHistory":'certification/admin/v1/workerInfo/getProjectHistory',
      "getCredential":'certification/admin/v1/workerInfo/getCredential',
    },
    'entryExit':{
      'entry':'certification/admin/v1/entryExit/entry',
      'exit':'certification/admin/v1/entryExit/exit',
      'getData':'certification/admin/v1/entryExit/getData',
      'uploadFile':'certification/admin/v1/entryExit/uploadFile',
    },
    'common':{
      //其他企业表相关操作
      'getCorpItem':'common/admin/v1/corp/getCorpItem',
      //获取参建单位
      'projectCorpList':'common/admin/v1/corp/projectCorpList'
    },
    'team':{
      'getList':'certification/admin/v1/team/getList',
      'getData':'certification/admin/v1/team/getData',
      'getDic':'certification/admin/v1/team/getDic',
      'getInfoDic':'certification/admin/v1/team/getInfoDic',
      'create':'certification/admin/v1/team/create',
      'update':'certification/admin/v1/team/update',
    'exit':'certification/admin/v1/team/exit',
      'entry':'certification/admin/v1/team/entry',
      'createQrCode':'certification/admin/v1/team/createQrCode',
      'getTeamName':'certification/admin/v1/team/getTeamName',
    },
    'attendance':{
      'getList':'certification/admin/v1/attendance/getList',
      "getStaffClockIn":'certification/admin/v1/attendance/clockIn',
      "clockInDetail":'certification/admin/v1/attendance/clockInDetail',
      "excelExporte":'certification/admin/v1/attendance/excelExporte',
    },
    'machineManage':{
      'getList':'certification/admin/v1/machineManage/getList',
      'create':'certification/admin/v1/machineManage/create',
      'update':'certification/admin/v1/machineManage/update',
      'destroy':'certification/admin/v1/machineManage/destroy',
      'searchMachine':'certification/admin/v1/machineManage/searchMachine',
      'sync':'certification/admin/v1/machineManage/sync',
      'syncMemberList':'certification/admin/v1/machineManage/syncMemberList',
      'failLog':'certification/admin/v1/machineManage/failLog',
      'entryAgain':'certification/admin/v1/machineManage/entryAgain',
      'exitAgain':'certification/admin/v1/machineManage/exitAgain',
      'reset':'certification/admin/v1/machineManage/reset',
      'getOption':'certification/admin/v1/machineManage/getOption',
      'getMachineTeamName':'certification/admin/v1/machineManage/getMachineTeamName',
    },
    'overview':{
      'getData':'certification/admin/v1/overview/getData',
    },
    'pay':{
      'getList':'certification/admin/v1//pay/getList',
      'excelExporte':'certification/admin/v1//pay/excelExporte',
      'getBackList':'certification/admin/v1//pay/getBackList',
      'fileCreate':'certification/admin/v1//pay/fileCreate',
      'fileDestroy':'certification/admin/v1//pay/fileDestroy',
      'fileUpdate':'certification/admin/v1//pay/fileUpdate',
      'file':'certification/admin/v1//pay/file',
      'getBackDic':'certification/admin/v1//pay/getBackDic',
      'payRollCreate':'certification/admin/v1//pay/payRollCreate',
      'getSetPayDic' : 'certification/admin/v1/pay/getSetPayDic',
      'payUpdate' : 'certification/admin/v1/pay/payUpdate',
    },
    'governmentStatus':{
      'getList':'certification/admin/v1/governmentStatus/getList',
      'getErrorCount':'certification/admin/v1/governmentStatus/getErrorCount'
    },
  }
}
module.exports =  certification;
